import React from "react";
import useLocalStorage from "../../hooks/useLocalStorage";

export enum APIEnvironment {
  DEV = "dev",
  PROD = "prod",
}
interface AppContextState {
  companyName: string;
  apiEnvironment: APIEnvironment;
}

export const AppContext = React.createContext<any>({});

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactChild;
}) => {
  const [state, setState] = useLocalStorage<AppContextState>("appState", {
    companyName: "My Islamic Will",
    apiEnvironment: APIEnvironment.PROD,
  });

  return (
    <AppContext.Provider
      value={{
        state,
        setState: (newState: Partial<AppContextState>) => {
          setState({
            ...state,
            ...newState,
          });
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
