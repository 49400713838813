import React from "react";
import { logolgLight } from "../../images";

export function Loading() {
  return (
    <div
      className="d-flex align-items-center"
      style={{ height: "100vh", width: "100vw" }}
    >
      <div className="text-center w-100">
        <img alt="Islamic Wills logo" src={logolgLight} width="200px" />
      </div>
    </div>
  );
}

export default Loading;
