import { useState, useCallback } from "react";

function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (err) {
      console.log({ err }, "failed to initialize localStorage");

      return initialValue;
    }
  });

  const setValue = useCallback((value: T) => {
    try {
      setStoredValue(value);

      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
      console.log({ err, value }, "failed to store value in localstorage");
    }
  }, [key]);

  return [storedValue, setValue];
}

export default useLocalStorage;
