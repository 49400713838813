import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  QueryParamProvider,
  StringParam,
  useQueryParam,
} from "use-query-params";
import loadable from "@loadable/component";
import AppContext, { APIEnvironment, AppContextProvider } from "./contexts/app";
import Loading from "./components/Loading";

import "./index.css";

const loadableOpts = { fallback: <Loading /> };

const HomePageComponent = loadable(
  () => import("./pages/HomePage"),
  loadableOpts
);
const AppPrivacyPolicy = loadable(
  () => import("./pages/AppPrivacyPolicy"),
  loadableOpts
);
const WillsForm = loadable(() => import("./pages/WillsForm"), loadableOpts);
const WillPreview = loadable(() => import("./pages/WillPreview"), loadableOpts);
const WillReview = loadable(() => import("./pages/WillReview"), loadableOpts);
const Guides = loadable(() => import("./pages/Guides"), loadableOpts);

function EnvironmentToggle() {
  const [envParam, setEnvParam] = useQueryParam("env", StringParam);
  const { state, setState } = React.useContext(AppContext);

  useEffect(() => {
    const env = [APIEnvironment.DEV, APIEnvironment.PROD].find(
      (key) => key === envParam
    );

    if (env) {
      console.log("env", env);
      setState({ apiEnvironment: env });
      setEnvParam(undefined);
    }
  }, [envParam, setEnvParam, setState]);

  if (state.apiEnvironment === APIEnvironment.DEV) {
    return (
      <div
        style={{
          width: "100%",
          backgroundColor: "red",
          height: "1px",
          position: "fixed",
          zIndex: 99999,
        }}
      />
    );
  }

  return <></>;
}

function App() {
  return (
    <AppContextProvider>
      <QueryParamProvider>
        <EnvironmentToggle />
        <Router>
          <Switch>
            <Route exact path="/" children={<HomePageComponent />} />
            <Route path="/privacy-policy" children={<AppPrivacyPolicy />} />
            <Route path="/wills-form" children={<WillsForm />} />
            <Route path="/will-preview" children={<WillPreview />} />
            <Route path="/will-review" children={<WillReview />} />
            <Route path="/guides/:slug?" children={<Guides />} />
          </Switch>
        </Router>
      </QueryParamProvider>
    </AppContextProvider>
  );
}

export default App;
